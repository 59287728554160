import React, { useRef } from 'react';

import get from 'lodash/get';

import {
  Hero,
  HeroDynamic,
  HeroCarousel,
  HeroBubble,
  WrapperIntro,
} from 'components/organisms';
import Intro from 'components/molecules/intro/Intro';
import Subhead from 'components/atoms/subhead/Subhead';
import { getFilteredComponents } from 'utils/get-filtered-components';
import { ModuleRenderer } from 'utils/module-renderer';
import { PageDataLoader, fetchPageData } from 'utils/page-data-loader';
import { VerifyPageContext } from 'utils/verify-page-context';
import { IntroContext } from 'contexts/intro-context';
import { QUERY_TYPES } from 'constants/query';
import { FULL_BLEED_COMPONENTS } from 'constants/modules';
import { Portal } from 'components/atoms/portal';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import LocaleNudgeBanner from '../components/molecules/locale-nudge-banner/LocaleNudgeBanner';

/**
 * Renders the Home Page template.
 * @param {Object} pageData The Contentful data object.
 */
const HomePage = ({ pageData }) => {
  const scrollToRef = useRef(null);

  const {
    modulesCollection: { items: components },
    __typename: pageContext,
    hero,
    heroCarousel,
    heroDynamic,
    heroBubble,
    subhead,
    introText,
  } = pageData;

  const verifiedContext = VerifyPageContext(pageContext);
  const [{ __typename: moduleType }] = components;
  const isFullBleedComponent = FULL_BLEED_COMPONENTS.includes(moduleType);
  const filteredComponents = getFilteredComponents(components);

  return (
    <article>
      {/* Hero */}
      {heroCarousel && (
        <HeroCarousel data={pageData} pageContext={verifiedContext} />
      )}

      {/* Hero */}
      {heroBubble && !heroCarousel && (
        <HeroBubble data={pageData} scrollRef={scrollToRef} />
      )}

      {/* Hero */}
      {hero && !heroCarousel && !heroBubble && (
        <Hero
          key={get(hero, 'sys.id')}
          data={pageData}
          pageContext={verifiedContext}
        />
      )}

      {/* Hero */}
      {heroDynamic && !heroCarousel && !hero && !heroBubble && (
        <HeroDynamic data={pageData} pageContext={verifiedContext} />
      )}
      <div ref={scrollToRef}>
        {(introText || subhead) && (
          <IntroContext.Provider
            value={{
              withHeight: isFullBleedComponent,
              withIntro: !!introText,
            }}
          >
            <WrapperIntro>
              {/* Subhead */}
              {subhead && <Subhead>{subhead}</Subhead>}

              {/* Intro Text */}
              {introText && <Intro bodyText={introText} />}
            </WrapperIntro>
          </IntroContext.Provider>
        )}
        {/* Modules */}
        <section>
          <ModuleRenderer
            components={filteredComponents}
            pageContext={verifiedContext}
          />
        </section>
        <Portal>
          <LocaleNudgeBanner />
        </Portal>
      </div>
    </article>
  );
};

export default function HomePageWithData({
  navigationData,
  navCollectionName,
  footerData,
  footerCollectionName,
  pageData,
  pageError,
  pageLoading,
  isMobile,
  componentId,
}) {
  return (
    <PageDataLoader
      WrappedComponent={HomePage}
      componentId={componentId}
      showLowerNavHome={isMobile}
      navigationData={navigationData}
      navCollectionName={navCollectionName}
      footerData={footerData}
      footerCollectionName={footerCollectionName}
      pageData={pageData}
      pageError={pageError}
      pageLoading={pageLoading}
    />
  );
}

export async function getServerSideProps(context) {
  const {
    req,
    locale,
    query: { tag, slug },
  } = context;

  // isMobile test on user agent since window is not available in server side
  const userAgent = req.headers['user-agent'] || '';
  const regex = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
  const isMobile = regex.test(userAgent);

  const data = await fetchPageData({
    locale,
    pageSlug: false,
    queryType: QUERY_TYPES.PAGE_HOME_QUERY,
  });

  return {
    props: {
      ...data,
      ...(await serverSideTranslations(locale, ['common'])),
      isMobile,
      componentId: `${locale}-${tag}-${slug}`,
    },
  };
}
